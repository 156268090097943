.modal1 {
    position: fixed;
    top: 0;
    left: 0;
    width:100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    z-index: 1055;
    overflow-x: hidden;
    overflow-y: initial;
    outline: 0;
  }
  
  .modal-main {
    position:absolute;
    background: white;
    width: 80%;
    height: auto;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    border-radius: 15px;
  }

  .modal-body-scroll {
    max-height: calc(100vh - 200px);
    overflow-y: auto;
    word-wrap: break-word;
    padding-right: 10px;
    margin-right: -10px;
  }
  
  .display-block {
    display: block;
  }
  
  .display-none {
    display: none;
  }

  .modal-header {
    display: block !important;
    text-align: right;
    padding: 0 !important;
    margin-bottom: 15px;
  }

  .close-dialog-btn {
    background-color: #fff;
    border: none;
    font-size: 24px;
    display: inline-block;
    margin-top: -10px;
  }

  .service-config.container,
  .container.topic {
    border: 1px solid #b1b1b1;
    border-radius: 4px;
  }