.App {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh; 
    padding: 3rem 0.5rem;
  }
  
  section {
    max-width: 650px;
    min-height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 1rem;
    background-color: rgba(150, 150, 150, 0.4);
}

form {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    flex-grow: 1;
    padding-bottom: 1rem;
}

input[type="text"],
input[type="password"],
button,
textarea {
  font-family: 'Nunito', sans-serif;
  font-size: 22px;
  padding: 0.25rem;
  border-radius: 0.5rem;
}

label,
button {
  margin-top: 1rem;
}

button {
  padding: 0.5rem;
}

body {
  background-color: #f9f9f9 !important;
}

.navbar-dark .navbar-brand {
  color: #333 !important;
}

.nav-bar-text {
  color: #333;
}

#navBar {
  border-bottom: 1px solid #ddd;
  background-color: #fff !important;
}

.navigation-user-holder {
  display: flex;
  align-items: center;
}

.logout-btn {
  margin-left: 15px !important;
  border: none;
  background-color: transparent;
}

.m-l-10 {
  margin-left: 5px;
}

.user-icon {
  margin-right: 5px;
}

button svg {
  margin-right: 5px;
}

.btn-default {
  background-color: #fff !important;
  border: 1px solid #ddd !important;
}