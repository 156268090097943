.service_container {
    display: flex;
    border: 1px solid #ddd;
    width: 350px;
    height: 200px;
    margin: 10px;
    display: flex;
    position: relative;
    display: inline-block;
    border-radius: 8px;
    background-color: #fff;
    cursor: pointer;
}

.activity_container {
    position: absolute;
    margin-top: 45px;
}

.active {
    border-top: 5px solid #28a745;
}

.service_name {
    padding: 5px;
    text-align: center;
    margin-bottom: 45px !important;
}

.undefinedActivity {
    border-top: 5px solid #d01919;
}

.notActive {
    border-top: 5px solid orange;
}

.service_container.active i {
    color: green;
}

.service_container.notActive i {
    color: orange;
}

.service_container.undefinedActivity i {
    color: red;
}

.topics {
    text-align: center;
    position: absolute;
    display: inline-block;
    border: 1px solid black;
    background-color: rgba(241, 242, 242, 0.9);
    width: 600px;
    height: 350px;
    z-index: 1;
    bottom: 10%;
    top: 15%;
    right: 50%;
    transform: translate(50%, 0%);
    background-color: #fff;
    padding: 10px;
    border-radius: 8px;
    border: 1px solid #ddd;
}

.wrapped-component {
    margin-bottom: 10px;
}